import { initialState } from './state';
import "bootstrap/bootstrap.bundle"; // load bootstrap JavaScript
import { Aurelia, LogManager } from "aurelia-framework";
import environment from "../config/environment.json";
import config from "./authConfigs";
import { PLATFORM } from "aurelia-pal";
import { I18N, TCustomAttribute } from "aurelia-i18n";
import { AppRouter } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import Backend from "i18next-http-backend"; //"i18next-http-backend";
import { SentryAppender } from 'aurelia-sentry';
// import "i18next";

LogManager.addAppender(new SentryAppender());
LogManager.setLevel(LogManager.logLevel.warn);

export function configure(aurelia: Aurelia): void {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName("resources/index"));
  aurelia.use.plugin("aurelia-i18n", (instance) => {
    let aliases = ["t", "i18n"];
    // add aliases for 't' attribute
    TCustomAttribute.configureAliases(aliases);

    // register backend plugin
    instance.i18next.use(Backend);

    // adapt options to your needs (see https://i18next.com/docs/options/)
    // make sure to return the promise of the setup method, in order to guarantee proper loading
    return instance.setup({
      backend: {
        // <-- configure backend settings
        loadPath: "./locales/{{lng}}/{{ns}}.json", // <-- XHR settings for where to get the files from
      },
      attributes: aliases,
      lng: "fr",
      fallbackLng: "en",
      debug: false,
    })
     .then(() => {
       const router = aurelia.container.get(AppRouter);
       router.transformTitle = title => instance.tr(title);
       const eventAggregator = aurelia.container.get(EventAggregator);
       eventAggregator.subscribe('i18n:locale:changed', () => {
         router.updateTitle();
       })
      });
  });
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-validation'))
  aurelia.use.developmentLogging(environment.debug ? "debug" : "warn");

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName("aurelia-testing"));
  }

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-auth"), (baseConfig) => {
    baseConfig.configure(config);
  });

  //Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName("aurelia-animator-css"));

  //Plugin for Dialogs
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-dialog"),  config => {
    config.settings.lock = false;
  });

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-store'), { initialState });  // <----- REGISTER THE PLUGIN
  // config.settings.lock = false;
  //if the css animator is enabled, add swap-order="after" to all router-view elements

  //Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName("app")));
}

export async function checkResponseStatus(res) {
    if (res.ok) {
        return res
    } else {
        // hrow new Error(`The HTTP status of the reponse: ${res.status} (${res.statusText}): ${res.text}`);
        let error = await res.json();
        //console.log(`The HTTP status of the reponse: ${res.status}(${res.statusText}): ${JSON.stringify(error)}`);
        if (error?.error)
            throw error.error;
        else if (error?.errors)
            throw error.errors;
        else
            throw error;
    }
}
